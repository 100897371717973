import React, { useState } from 'react'
import styled from 'styled-components'
import InfoModal from 'components/InfoModal'
import {
  grid,
  position,
  space,
  typography,
  border,
  shadow,
  background,
} from 'styled-system'
import {
  Container,
  Title,
  Text,
  Image,
  Subtitle,
  Footer,
  Icon,
} from '../components'

const Wrapper = styled.div`
  width: 100%;
  color: rgba(255, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0.5);
  background-color: rgba(255, 0, 0, 0.5);
`

const projectPlaceholder = {
  title: `Información del proyecto`,
  body: `Sección en construcción...`,
}

const aboutArbotag = {
  title: `¿Qué es Arbotag?`,
  body: `Sección en construcción...`,
}

const Description = styled.p`
  ${typography}
  ${space}
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
`

Description.defaultProps = {
  textAlign: `left`,
}

const InnerWrapper = styled.div`
  ${position}
  ${border}
  ${shadow}
  ${background}
  ${space}
  width: 100%;
`
InnerWrapper.defaultProps = {
  position: `static`,
  top: 0,
  borderRadius: 32,
  boxShadow: `0px 8px 16px rgba(0, 0, 0, 0.1)`,
  background: `#fff`,
}

const Grid = styled.div`
  ${grid}
  ${space}
  display: grid;
  padding-right: 25px;
`
Grid.defaultProps = {
  pb: 0,
  pl: 0,
}

const Col = styled.div`
  width: 100%;
`

const GridTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 25px;
`

const TreeInfo = ({ data: tree, projectInfo }) => {
  const [modalProject, setModalProject] = useState(false)
  const [modalAbout, setModalAbout] = useState(false)

  return (
    <>
      <InfoModal
        onConfirm={() => {
          setModalProject(false)
        }}
        information={projectInfo ? projectInfo : projectPlaceholder}
        isVisible={modalProject}
      />
      <InfoModal
        onConfirm={() => {
          setModalAbout(false)
        }}
        information={aboutArbotag}
        isVisible={modalAbout}
      />
      <Wrapper style={{ backgroundColor: `rgba(255,255,255,0.5)` }}>
        <Image
          species={tree?.specie}
          onClickProject={() => setModalProject(true)}
          onClickAbout={() => setModalAbout(true)}
        />
        <Container p="23px 28px" borderRadius={32} shadow>
          <Title>{tree?.specie?.name || `N/D`}</Title>
          <Description>{tree?.specie?.scientificName || `N/D`}</Description>
          <InnerWrapper pt={46} background="transparent" boxShadow="none">
            <Grid gridTemplateColumns="100%">
              <Col>
                <GridTitle>descripcion</GridTitle>
                <Text>{tree?.specie?.description || `NE`}</Text>
              </Col>
              <Col>
                <GridTitle>género</GridTitle>
                <Text fontStyle="italic">{tree?.specie?.kind || `NE`}</Text>
              </Col>
              <Col>
                <GridTitle>clasificación por tipo de hoja</GridTitle>
                <Text fontStyle="italic">
                  {tree?.specie?.clasification || `NE`}
                </Text>
              </Col>
              <Col>
                <GridTitle>familia</GridTitle>
                <Text fontStyle="italic">{tree?.specie?.family || `NE`}</Text>
              </Col>
            </Grid>
            <Grid>
              <Col>
                <GridTitle>origen</GridTitle>
                <Text>{tree?.specie?.geographyOrigin || `NE`}</Text>
              </Col>
            </Grid>
            <GridTitle>características</GridTitle>
            <Grid
              style={{ textAlign: `center` }}
              gridTemplateColumns="auto auto auto auto"
              mt={12}
              pr={0}
            >
              <Col margin="auto" textAlign="center">
                <Icon
                  field="sunRequirement"
                  value={tree.specie.sunRequirement}
                />
              </Col>
              <Col margin="auto" textAlign="center">
                <Icon
                  field="waterRequirement"
                  value={tree.specie.waterRequirement}
                />
              </Col>
              <Col margin="auto" textAlign="center">
                <Icon field="growthSpeed" value={tree.specie.growthSpeed} />
              </Col>
              <Col margin="auto">
                <Icon field="topShape" value={tree.specie.topShape} />
              </Col>
              <Col>
                <Text textAlign="center" fontSize={12}>
                  {tree?.specie?.sunRequirement || `ND`}
                </Text>
              </Col>
              <Col>
                <Text textAlign="center" fontSize={12}>
                  Riego {tree?.specie?.waterRequirement || `ND`}
                </Text>
              </Col>
              <Col>
                <Text textAlign="center" fontSize={12}>
                  Crecimiento {tree?.specie?.growthSpeed || `ND`}
                </Text>
              </Col>
              <Col>
                <Text textAlign="center" fontSize={12}>
                  {tree?.specie?.topShape || `ND`}
                </Text>
              </Col>
            </Grid>
          </InnerWrapper>
          <Subtitle>Ficha del espécimen</Subtitle>
          <InnerWrapper>
            <Grid gridTemplateColumns="50% 50%" pl={25}>
              <Col>
                <GridTitle>ID.</GridTitle>
                <Text>{tree?.id || `N/D`}</Text>
                <GridTitle>Ubicación</GridTitle>
                <Text>{tree?.address || `N/D`}</Text>
              </Col>
              <Col>
                <GridTitle>Unidad Vecinal</GridTitle>
                <Text>{tree?.subUnit?.description || `N/D`}</Text>
                <GridTitle>Dap</GridTitle>
                <Text>{tree?.dap?.description || `N/D`}</Text>
              </Col>
            </Grid>
            <Grid gridTemplateColumns="100%" pl={25} pb={56}>
              <Col>
                <GridTitle>Fase de desarrollo</GridTitle>
                <Text>{tree?.relativeAge?.description || `N/D`}</Text>
              </Col>
            </Grid>
          </InnerWrapper>
          <InnerWrapper background="transparent" boxShadow="none">
            <Footer
              showLogo
              showCredit={false}
              unitId={tree?.unitId}
              logoUrl={tree?.logoUrl}
            />
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  )
}

export default TreeInfo
