import React from 'react'
import styled from 'styled-components'
import AntdSelect from 'antd/es/select'

const StyledSelect = styled(AntdSelect)`
  background: #fff;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38);
  height: 64px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 32px;
  padding-left: 24px;
  padding-right: 36px;
  display: flex;
  align-items: center;
  & .ant-select-selector {
    border: none !important;
  }
  & .ant-select-arrow {
    width: 10px;
    height: 6px;
    right: 24px;
    background-color: rgba(0, 0, 0, 1);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
  & .ant-select-arrow .anticon {
    display: none;
  }
`
const Select = ({ options, onChange, ...otherProps }) => (
  <StyledSelect onChange={onChange} {...otherProps} placeholder="Motivo">
    {options?.length && options.length > 0
      ? options.map((o) => (
          <StyledSelect.Option key={o.id} value={o.description}>
            {o.description}
          </StyledSelect.Option>
        ))
      : null}
  </StyledSelect>
)

export default Select
