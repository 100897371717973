import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Container,
  Image,
  Subtitle,
  Select,
  TextArea,
  Text,
} from '../components'

const Wrapper = styled.div`
  width: 100%;
`
const Button = styled.button`
  padding: 10px 15px;
  color: rgba(163, 203, 56, 1);
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin-top: 32px;
  margin-left: calc(100% - 104px);
  opacity: ${(props) => (props.disabled ? `0.5` : `1`)};
`

const RequestForm = ({ types, handleTypeSelected }) => {
  const [optionSelected, setOptionSelected] = useState({})
  const handleOptionChange = (name) => (e) => {
    if (name === `id`) {
      const selection = types.find((i) => i.description === e)
      if (selection) {
        setOptionSelected((prevState) => ({
          ...prevState,
          [name]: selection.id,
        }))
      }
    } else {
      setOptionSelected((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }))
    }
  }
  return (
    <Wrapper>
      <Image />
      <Container p="25px 16px" top={188} borderRadius="32px 32px 0px 0px">
        <Text fontWeight={300} fontSize={18} mb={60} lineHeight="21px">
          Ingresar solicitud
        </Text>
        <Subtitle>Detalles de la solicitud</Subtitle>
        <Select options={types} onChange={handleOptionChange(`id`)} />
        <TextArea onChange={handleOptionChange(`description`)} />
        <Button
          type="button"
          onClick={() => handleTypeSelected(optionSelected)}
          disabled={Object.keys(optionSelected).length === 0}
        >
          siguiente
        </Button>
      </Container>
    </Wrapper>
  )
}

export default RequestForm
