import React from 'react'
import styled from 'styled-components'
import {
  ICON_GROWTH_LOW,
  ICON_GROWTH_MEDIUM,
  ICON_GROWTH_FAST,
  ICON_SUN_SEMI,
  ICON_SUN_SUN,
  ICON_WATER_LOW,
  ICON_WATER_MEDIUM,
  ICON_WATER_HIGH,
  ICON_TOP_ABANICO,
  ICON_TOP_COLUMN,
  ICON_TOP_CONIC,
  ICON_TOP_ESFERIC,
  ICON_TOP_EXTENDED,
  ICON_TOP_IRREGULAR,
  ICON_TOP_PALMIFORM,
  ICON_TOP_PENDULAR,
  ICON_TOP_OVOIDAL,
} from 'assets'

const ImgStyled = styled.img`
  max-width: 80%;
  height: auto;
  text-align: center;
  max-height: 54px;
`
const icons = {
  growthSpeed: {
    lento: ICON_GROWTH_LOW,
    medio: ICON_GROWTH_MEDIUM,
    rápido: ICON_GROWTH_FAST,
  },
  sunRequirement: {
    'semi sombra': ICON_SUN_SEMI,
    sol: ICON_SUN_SUN,
  },
  waterRequirement: {
    bajo: ICON_WATER_LOW,
    medio: ICON_WATER_MEDIUM,
    alto: ICON_WATER_HIGH,
  },
  topShape: {
    abanico: ICON_TOP_ABANICO,
    columnar: ICON_TOP_COLUMN,
    cónica: ICON_TOP_CONIC,
    esférica: ICON_TOP_ESFERIC,
    extendida: ICON_TOP_EXTENDED,
    irregular: ICON_TOP_IRREGULAR,
    palmiforme: ICON_TOP_PALMIFORM,
    pendular: ICON_TOP_PENDULAR,
    ovoidal: ICON_TOP_OVOIDAL,
  },
}

const Icon = ({ field, value, ...props }) => (
  <ImgStyled src={icons[field][value.toLowerCase()]} {...props} />
)

export default Icon
