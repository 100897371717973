import React from 'react'
import { Loader, Modal } from '../components'

const ScanView = ({ openModal, onCancel, onConfirm }) => {
  return (
    <>
      <Loader />
      <Modal
        isVisible={openModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title="Id del árbol"
      />
    </>
  )
}

export default ScanView
