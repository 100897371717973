import React from 'react'
import { layout } from 'styled-system'
import styled from 'styled-components'
import { ARBOTAG_LOADER } from '../assets'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(163, 203, 56, 1);
`
const Icon = styled.div`
  background-image: url(${ARBOTAG_LOADER});
  background-repeat: no-repeat;
  background-size: contain;
  ${layout}
`

export default function Loader({ width = 70, height = 56 }) {
  return (
    <Container>
      <Icon width={width} height={height} />
    </Container>
  )
}
