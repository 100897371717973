import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale-provider/es_ES'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById(`root`),
)
