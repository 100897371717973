import React from 'react'
import styled from 'styled-components'
import { space, typography } from 'styled-system'

const StyledText = styled.p`
  ${typography}
  ${space}
  color: rgba(0, 0, 0, 0.85);
  margin-top: 7px;
`
StyledText.defaultProps = {
  fontSize: 16,
  lineHeight: `19px`,
}

const Text = ({ children, ...otherProps }) => {
  return <StyledText {...otherProps}>{children}</StyledText>
}
export default Text
