import styled from 'styled-components'
import { typography } from 'styled-system'

const StyledTitle = styled.h2`
  ${typography}
  font-size: 24px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
`

export default StyledTitle
