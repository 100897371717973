import axios from 'axios'
import { TOKEN } from 'config/constants'

const API = {}

const baseURL = process.env.REACT_APP_API_URL || `http://localhost:4000/`

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(TOKEN)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject(error),
)

API.getTree = (treeId) =>
  axiosInstance.post(`/tree/getScannedTree`, { treeId, isWebRequest: true })

API.getRequestTypes = () => axiosInstance.get(`/request/getRequestTypes`)

API.newRequest = (request) => axiosInstance.post(`/request`, request)

export default API
