import React from 'react'
import styled from 'styled-components'
import AntdModal from 'antd/es/modal'
import 'antd/es/modal/style/index.css'
import Button from './Button'

const ModalStyled = styled(AntdModal)`
  & .ant-modal-header {
    background-color: rgba(235, 235, 235, 1);
  }
  & .ant-modal-body {
    background-color: rgba(235, 235, 235, 1);
    text-align: center;
  }
`

const InfoModal = ({ onConfirm, isVisible = false, information }) => {
  return isVisible ? (
    <ModalStyled
      title={information.title}
      visible={isVisible}
      onCancel={onConfirm}
      centered
      footer={null}
      maskStyle={{ backgroundColor: `transparent` }}
      transparent
    >
      <h3>{information.body}</h3>
      <Button
        onClick={() => onConfirm()}
        style={{
          padding: `12px 18px 13px 18px`,
          margin: `20px 20px 20px 20px`,
        }}
      >
        Cerrar
      </Button>
    </ModalStyled>
  ) : null
}

export default InfoModal
