import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Container, Image, Subtitle, Button, Text, Input } from '../components'

const Wrapper = styled.div`
  width: 100%;
  & button {
    display: block;
    margin: 34px auto;
  }
`
const validator = {
  null: (value) => value !== null || value !== undefined,
  empty: (value) => value && value.trim().length > 0,
  email: (value) => {
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    return value && regex.test(value.trim())
  },
}

const UserForm = ({ handleUserData }) => {
  const [userData, setUserData] = useState({})
  const handleChange = (name) => (event) => {
    const { value } = event.target
    setUserData((prevState) => ({ ...prevState, [name]: value }))
  }
  const isFormValid = useMemo(() => {
    const keys = Object.keys(userData)
    const isValid = []
    if (keys.length === 2) {
      keys.forEach((key) => {
        if (validator.null(userData[key]) && validator.empty(userData[key])) {
          if (
            (key === `userMail` && validator.email(userData[key])) ||
            key === `userName`
          ) {
            isValid.push(key)
          }
        }
      })
    }
    return isValid.length === 2
  }, [userData])

  return (
    <Wrapper>
      <Image />
      <Container p="25px 16px" top={188} borderRadius="32px 32px 0px 0px">
        <Text fontWeight={300} fontSize={18} mb={60} lineHeight="21px">
          Ingresar tus datos para que te notifiquemos sobre la solicitud
        </Text>
        <Subtitle>Tus datos</Subtitle>
        <Input
          placeholder="Nombre"
          type="text"
          onChange={handleChange(`userName`)}
          required
        />
        <Input
          placeholder="Correo"
          type="email"
          onChange={handleChange(`userMail`)}
          required
        />
        <Button
          type="button"
          onClick={() => handleUserData({ userData })}
          disabled={!isFormValid}
        >
          Ingresar solicitud
        </Button>
      </Container>
    </Wrapper>
  )
}

export default UserForm
