import React from 'react'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  background: #fff;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.38);
  height: 163px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 32px;
  padding: 20px 24px;
  margin-top: 12px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
`

const TextArea = ({ onChange, otherProps }) => (
  <StyledTextArea onChange={onChange} {...otherProps} placeholder="Detalles" />
)

export default TextArea
