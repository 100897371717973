import React from 'react'
import styled from 'styled-components'
import { position } from 'styled-system'

const ButtonStyled = styled.button`
  ${position}
  background: rgba(163, 203, 56, 1);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  border: 2px solid rgb(255, 255, 255);
  opacity: ${(props) => (props.disabled ? `0.5` : `1`)};
  cursor: pointer;
  width: 73.33%;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
`
ButtonStyled.defaultProps = {
  position: `static`,
  top: 0,
  fontSize: `16px`,
}

const Button = ({ children, ...props }) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>
}

export default Button
