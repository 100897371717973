import { ALERT, CHECK } from 'assets'
import React from 'react'
import styled from 'styled-components'
import { space, typography } from 'styled-system'
import { Container, Image, Title, Footer, Button } from '../components'

const Description = styled.p`
  ${typography}
  ${space}
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
`

const Icon = styled.img`
  width: 100%;
  height: auto;
`
const Box = styled.div`
  margin: auto;
  width: 64px;
  margin-bottom: 36px;
  margin-top: 67px;
`
const InnerWrapper = styled.div`
  width: 80%;
  margin: 0 auto 91px auto;
  & button {
    display: block;
    margin: 34px auto;
  }
`

const ResponseView = ({ error }) => {
  return (
    <div>
      <Image />
      <Container p="25px 16px" top={188} borderRadius="32px 32px 0px 0px">
        <InnerWrapper>
          <Box>
            <Icon src={error ? ALERT : CHECK} />
          </Box>
          <Title textAlign="center">
            {error
              ? `¡Ha ocurrido un error!`
              : `¡Solicitud ingresada exitosamente!`}
          </Title>
          <Description textAlign="center" pt={10} pl={25} pr={25} pb={14}>
            {error
              ? `Intenta nuevamente`
              : `Te notificaremos por correo el estado de la solicitud.`}
          </Description>
          {error && (
            <Button
              onClick={() => {
                location.reload()
              }}
            >
              Regresar
            </Button>
          )}
        </InnerWrapper>
        <Footer />
      </Container>
    </div>
  )
}

export default ResponseView
