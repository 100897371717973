import React, { useEffect, useState } from 'react'
import GlobalStyle from './GlobalStyle'
import {
  ScanView,
  TreeInfo,
  RequestForm,
  UserForm,
  ResponseView,
} from './screens'
import API from './config/api'
import 'antd/dist/antd.css'

function App() {
  const [data, setData] = useState(null)
  const [projectInfo, setProjectInfo] = useState(null)
  const [active, setActive] = useState(0)
  const [types, setTypes] = useState([])
  const [request, setRequest] = useState({})
  const [error, setError] = useState(false)
  const { location } = window
  const urlParams = new URLSearchParams(location.search || null)
  const [treeId, setTreeId] = useState(
    urlParams.has(`id`) && urlParams.get(`id`),
  )
  const [openModal, setOpenModal] = useState(false)

  const handleSubmit = (id) => {
    setTreeId(id)
    setOpenModal(false)
  }
  const handleCloseModal = () => setOpenModal(false)
  const handleTypes = async () => {
    const {
      data: { result },
    } = await API.getRequestTypes()
    setActive(2)
    setTypes(result)
    window.scroll(0, 0, `smooth`)
  }
  const handleTypeRequest = (selection) => {
    setRequest({
      treeId: data.id,
      description: selection.description,
      requestTypeId: selection.id,
    })
    setActive(3)
  }

  const handleRequest = async ({ userData }) => {
    try {
      await API.newRequest({
        request: {
          ...request,
          ...userData,
          createdById: null,
          requestStatusId: 1,
        },
      })
    } catch {
      setError(true)
    }
    setActive(4)
  }

  useEffect(() => {
    if (treeId) {
      API.getTree(treeId)
        .then(({ data: { result } }) => {
          const { tree, projectInfo } = result
          setData(tree)
          setProjectInfo(projectInfo)
          setActive(1)
        })
        .catch(() => {
          setActive(0)
          setOpenModal(true)
        })
    } else {
      setOpenModal(true)
    }
  }, [treeId])
  return (
    <div>
      <GlobalStyle />
      {active === 0 && (
        <ScanView
          openModal={openModal}
          onCancel={handleCloseModal}
          onConfirm={handleSubmit}
        />
      )}
      {data?.id && active === 1 && (
        <TreeInfo
          data={data}
          projectInfo={projectInfo}
          handleRequest={handleTypes}
        />
      )}
      {types && active === 2 && (
        <RequestForm types={types} handleTypeSelected={handleTypeRequest} />
      )}
      {active === 3 && <UserForm handleUserData={handleRequest} />}
      {active === 4 && <ResponseView error={error} />}
    </div>
  )
}

export default App
