import React, { useState } from 'react'
import styled from 'styled-components'
import AntdModal from 'antd/es/modal'
import 'antd/es/modal/style/index.css'
import Button from './Button'
import Input from './Input'

const ModalStyled = styled(AntdModal)`
  & .ant-modal-header {
    background-color: rgba(235, 235, 235, 1);
  }
  & .ant-modal-body {
    background-color: rgba(235, 235, 235, 1);
    text-align: center;
  }
`
const ModalWrapper = ({ onCancel, onConfirm, isVisible = false, title }) => {
  const [inputValue, setInputValue] = useState(null)
  const handleChange = (e) => {
    setInputValue(e.target.value)
  }
  return isVisible ? (
    <ModalStyled
      title={title}
      visible={isVisible}
      onCancel={onCancel}
      centered
      footer={null}
      maskStyle={{ backgroundColor: `transparent` }}
    >
      <Input
        onChange={handleChange}
        value={inputValue}
        placeholder="Ingrese el id del árbol"
      />
      <Button
        onClick={() => onConfirm(inputValue)}
        disabled={!inputValue}
        style={{ padding: `22px 38px 23px 38px` }}
      >
        Enviar
      </Button>
    </ModalStyled>
  ) : null
}

export default ModalWrapper
