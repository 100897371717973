import styled from 'styled-components'

const Logo = styled.img`
  position: absolute;
  top: 23px;
  right: 23px;
  width: 96px;
  height: 51px;
  object-fit: contain;
`

export default Logo
