import React from 'react'
import styled from 'styled-components'
import { space, grid } from 'styled-system'
import { INSTAGRAM, ARBOTAG_LOGO_COLOR, FACEBOOK, TWITTER } from '../assets'

const Credits = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.1);
  margin-top: 90px;
  margin-bottom: 14px;
`

const Badge = styled.img`
  width: 100%;
  height: 275px;
  object-fit: contain;
`
/*
const Description = styled.p`
  ${typography}
  ${space}
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
`
*/
const Grid = styled.div`
  ${grid}
  ${space}
  display: grid;
  padding-right: 25px;
`
Grid.defaultProps = {
  pb: 0,
  pl: 0,
}
/*
const Col = styled.div`
  width: 100%;
`
*/

const Footer = ({ showCredit, unitId, logoUrl }) => {
  return (
    <div>
      {/*<Description textAlign="center" pl={25} pr={25} pb={14}>
        ¿Quieres escanear más árboles y hacerles seguimiento? Descarga nuestra
        app en:
      </Description>
      <Grid gridTemplateColumns="50% 50%" pl={25}>
        <Col>
          <Badge src={GOOGLE} />
        </Col>
        <Col>
          <Badge src={GOOGLE} />
        </Col>
  </Grid>*/}

      {unitId !== 20 && (
        <Grid gridTemplateColumns="100%%" pl={25}>
          <a
            href="https://www.instagram.com/arbotag"
            target="_blank"
            rel="noreferrer"
          >
            <Badge src={INSTAGRAM} />
          </a>
        </Grid>
      )}

      {unitId === 20 && (
        <Grid
          style={{
            display: `flex`,
            flexFlow: `row wrap`,
            justifyContent: `space-between`,
            margin: `auto`,
          }}
        >
          <div style={{ flex: 1 }}>
            <a
              href="https://www.facebook.com/oficialmunicipalidadnunoa"
              target="_blank"
              rel="noreferrer"
              style={{ flex: 1 }}
            >
              <Badge src={FACEBOOK} style={{ flex: 1 }} />
            </a>
          </div>
          <div style={{ flex: 1 }}>
            <a
              href="https://www.instagram.com/muninunoa/"
              target="_blank"
              rel="noreferrer"
              style={{ flex: 1 }}
            >
              <Badge src={INSTAGRAM} style={{ flex: 1 }} />
            </a>
          </div>
          <div style={{ flex: 1 }}>
            <a
              href="https://twitter.com/MuniNunoa"
              target="_blank"
              rel="noreferrer"
              style={{ flex: 1 }}
            >
              <Badge src={TWITTER} style={{ flex: 1 }} />
            </a>
          </div>
        </Grid>
      )}
      <div style={{ paddingTop: `15px` }}>
        {<Badge src={logoUrl ? logoUrl : ARBOTAG_LOGO_COLOR} />}
      </div>
      {showCredit && (
        <Credits>Desarrollado por Keirón. {new Date().getFullYear()} ©</Credits>
      )}
    </div>
  )
}

export default Footer
