import React from 'react'
import styled from 'styled-components'
import { ARBOTAG_LOGO, TREE_IMAGE } from '../assets'
import Logo from './Logo'
import Button from './Button'

const HeroImage = ({ species, onClickProject, onClickAbout }) => {
  const Image = styled.div`
    background-image: url(${species?.url || TREE_IMAGE});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 480px;
  `

  return (
    <Image style={{ display: `flex` }}>
      <Button
        style={{
          width: `70px`,
          height: `50px`,
          marginTop: `20px`,
          marginLeft: `10px`,
          fontSize: `12px`,
          padding: `0px 0px 0px 0px`,
        }}
        onClick={() => onClickProject()}
      >
        Proyecto
      </Button>
      <Button
        style={{
          width: `95px`,
          height: `50px`,
          marginTop: `20px`,
          marginLeft: `10px`,
          fontSize: `12px`,
        }}
        onClick={() => onClickAbout()}
      >
        Sobre Arbotag
      </Button>
      <Logo src={ARBOTAG_LOGO} alt="logo" />
    </Image>
  )
}
export default HeroImage
