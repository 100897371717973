import styled from 'styled-components'

const Subtitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #a3cb38;
  margin-top: 33px;
  margin-bottom: 14px;
`

export default Subtitle
