import React from 'react'
import styled from 'styled-components'
import { space, position, border } from 'styled-system'

const ContainerStyled = styled.div`
  ${space}
  ${position}
  ${border}
  background: rgba(235, 235, 235, 1);
  box-shadow: ${(props) =>
    props.shadow ? `0px 8px 16px rgba(0, 0, 0, 0.1)` : `none`};
  width: 100%;
  min-height: 80vh;
`
ContainerStyled.defaultProps = {
  p: `25px 16px`,
  shadow: false,
  position: `absolute`,
  top: `396px`,
  borderRadius: 0,
}

const Container = ({ children, ...props }) => {
  return <ContainerStyled {...props}>{children}</ContainerStyled>
}

export default Container
